<template>
  <div class="app">
    <div class="app-h">退保申请</div>
    <div class="app-xt">
      <div class="app-b">
        <!-- <el-card class="box-card"> -->

        <!-- </el-card> -->
        <div class="app-c">
          <div class="input">
            <div>
              <div class="input-flex">
                <div class="flex-txt1">
                  <span class="flex-txt">*</span> 保函状态
                </div>
                <el-input
                  class="input-1"
                  placeholder="请填写"
                  v-model="inname.platformcode"
                  clearable
                >
                </el-input>
              </div>
              <div class="input-flex">
                <div class="flex-txt1">
                  <span class="flex-txt">*</span> 退保原因
                </div>
                <el-input
                  class="input-1"
                  placeholder="请填写"
                  v-model="inname.reason"
                  clearable
                >
                </el-input>
                <!-- <el-select
                  class="input-1"
                  v-model="value"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
              </div>
              <div class="input-flex">
                <div class="flex-txt1">
                  <span class="flex-txt">*</span> 保函金额
                </div>
                <div class="flex-xx">￥1000</div>
              </div>
              <div class="input-flex fel">
                <div class="flex-txt1 uppder">
                  <span class="flex-txt">*</span>补充描述和凭证
                </div>
                <div class="input-in">
                  <textarea
                    placeholder="补充描述，有助于快速处理"
                    v-model="inname.sign"
                    class="unaks"
                    type="text"
                  />
                  <el-upload
                    action="https://www.hnzyxsbh.com/mobile/common/upload/"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-success="chengg"
                    :on-remove="handleRemove"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="butt">
      <div @click="tijiao" class="button-i">提交支付</div>
    </div>
  </div>
</template>

<script>
import { userlogin, loginadd } from "@/api/user";
export default {
  data() {
    return {
      // options: [
      //   {
      //     value: "选项1",
      //     label: "状态1",
      //   },
      //   {
      //     value: "选项2",
      //     label: "状态2",
      //   },
      //   {
      //     value: "选项3",
      //     label: "状态三",
      //   },
      //   {
      //     value: "选项4",
      //     label: "龙须面",
      //   },
      //   {
      //     value: "选项5",
      //     label: "北京烤鸭",
      //   },
      // ],
      inname: {
        appkey: "",
        applyno: "TBaoHanXDCS2022071512241849470",
        baohanno: "TBaoHanXDCS2022071512241849470",
        platformcode: "",
        reason: "",
        sign: "",
        timestamp: "",
      },
      value: "",
      dialogImageUrl: "",
      dialogVisible: false,
      textarea1: "",
    };
  },
  created() {
    //     var bbc = this.$route.params.bbc;
    // this.inname.baohanno = bbc;
    this.userlogin(this.inname.baohanno);
    this.dateFormat("y-m-d h:i:s", new Date());
  },
  methods: {
    async userlogin(e) {
      var applyno = e;
      const { data } = await userlogin(applyno);
      var bbc = data.data;
      this.inname.appkey = bbc.appkey;
      this.inname.applyno = bbc.applyno;
      this.inname.baohanno = bbc.baohanno;
    },
    async tijiao() {
      var bbc = this.inname;
      const { data } = await loginadd(bbc);
      this.$notify({
        title: "提醒",
        message:" data.msg",
      });
            this.$router.push({
        name: "public",
        params: {
          bbc,
        },
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    chengg(response, file, fileList) {
      this.inname.sign = response.data.url;
    },
    dateFormat(formatStr, fdate) {
      var fTime,
        fStr = "ymdhis";
      if (!formatStr) formatStr = "y-m-d h:i:s";
      if (fdate) fTime = new Date(fdate);
      else fTime = new Date();
      var formatArr = [
        fTime.getFullYear().toString(),
        (fTime.getMonth() + 1).toString(),
        fTime.getDate().toString(),
        fTime.getHours().toString(),
        fTime.getMinutes().toString(),
        fTime.getSeconds().toString(),
      ];
      for (var i = 0; i < formatArr.length; i++) {
        formatStr = formatStr.replace(fStr.charAt(i), formatArr[i]);
      }
      this.inname.timestamp = formatStr;
      // console.log();
    },
    //调用js
  },
};
</script>


<style scoped>
html {
  width: 100vw;
}
/deep/ .el-input__icon {
  height: 120%;
}
.app {
  /* padding: 0 20%; */
}
.app-xt {
  padding: 0 8%;
}
.fel {
  height: 100%;
}
.input-in {
  width: 12.9375rem;
  /* height: 6.5rem; */
  background: #f8f8f8;
  /* opacity: 0.6; */
  border-radius: 3px;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-xx {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: #ff5858;
  margin-left: 14px;
}
.uppder {
  padding-right: 12px;
}

/deep/ .el-upload--picture-card {
  line-height: 2.5625rem;
}
/deep/ .el-upload--picture-card i {
  color: #000;
  font-size: 19px;
}
/deep/ .el-upload--picture-card {
  width: 2.5625rem;
  height: 2.5625rem;
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 8px;
  border: 2px dashed #a4a4a3;
  background: #f8f8f8;
  border-radius: 2px;
}
.unaks {
  width: 83%;
  resize: none;
  padding: 6px 1.0625rem;
  height: 36px;
  background-color: #f8f8f8;
  color: #000 !important;
  border: 0px;
  font-size: 9px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.input-flex {
  display: flex;
  margin-top: 10px;
}
.input-1 {
  width: 200px !important;
  width: 113px;
  /* height: 20px !important; */
}
.app-h {
  padding-left: 1.6875rem;
  width: 100%;
  height: 2.6875rem;
  line-height: 2.6875rem;
  background: #1890ff;
  font-size: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  margin-left: 14px !important;
  height: 27px !important;
}

.text {
  font-size: 14px;
}

.item {
  padding: 8px 0;
}

.box-card {
  width: 480px;
}
.flex-txt {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 130px;
  text-align: left;
}
.abc {
  padding-left: 24px;
}
.app-a {
  display: flex;
  margin-top: 1.75rem;
  position: relative;
}
.a-left {
  width: 50%;
  height: 3.0625rem;
  display: flex;
  line-height: 3.125rem;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  background: #cdebff;
  border: 0.0625rem solid #f0f3fb;
  border-right: 0;
  border-radius: 0.5625rem 0rem 0rem 0.5625rem;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.a-img {
  padding-top: 0.85rem;
  padding-right: 0.9375rem;
  width: 1.25rem;
  height: 1.25rem;
}
.aa-img {
  position: absolute;
  height: 48.96px;
  margin-top: 0.0625rem;
  padding: 0;
  left: 45%;
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 3.125rem;
  width: 50%;
  height: 3.0625rem;
  margin-top: 1px;

  background: #069cff;
  border-radius: 0.4375rem;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 9px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 2.8125rem;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0 25%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 1.875rem;
}
.app-b2 {
  display: flex;
  line-height: 1.5rem;
  font-size: 0.8125rem;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 1.625rem;
}
.b-txt {
  width: 18.5rem;
  height: 1.5rem;
  flex: 1;
  background: #f3f3f3;
  border: 0.0625rem solid #dedede;
  padding-left: 0.375rem;
  line-height: 1.5rem;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 1.0625rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 0.9375rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-top: 3rem;
  width: 18.75rem;
  height: 5.5%;
  padding: 0.3125rem 0;
  line-height: 1.875rem;
  border-radius: 1.25rem;
  background: #069cff;
  text-align: center;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
/* 上传 */
</style>
